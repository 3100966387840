<script>

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import ceiMixin from "@mixins/ceiMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanCondTravailService from "@services/bilanCondTravailService";
import ceiService from "@services/ceiService";

export default {
  components: {AkFormView, AkDropdown},
  mixins: [randomRef, utilsMixin, ceiMixin, roleMixin],
  metaInfo() {
    return {
      title: "bilan_cond_travail.synthese",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      ceiList: [],
      list: [],
      localFields: ['taux_retour_astreinte', 'nb1', 'nb2', 'nb3', 'nb4', 'nb5', 'nb6', 'nb7', 'nb8', 'nb11', 'meo_action_renforcee', 'meo_org_travail_spec', 'mep_prog_activite', 'org_particuliere_av_we'],
      dbFields: ['tauxRetourAstreinte', 'nb1', 'nb2', 'nb3', 'nb4', 'nb5', 'nb6', 'nb7', 'nb8', 'nb9', 'nb10', 'nb11', 'meoActionRenforcee', 'meoOrgTravailSpec', 'mepProgActivite', 'orgParticuliereAvWe'],
      isExportDownloading: false,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    let p1 = ceiService.findAllCurrent();
    p1.then(data => this.ceiList = data);

    let p2 = bilanCondTravailService.findSyntheseByCurrentYear();
    p2.then(data => this.list = data);

    let p3 = bilanCondTravailService.findAllYears();
    p3.then(data => {
      this.yearList.push({value: 0, label: this.$t('current_year')});
      data.forEach(year => this.yearList.push({value: year, label: year + ' - ' + (year + 1)}))
    });

    Promise.all([p1, p2, p3]).then(() => this.afterPromise());
  },
  methods: {
    initData() {
      this.initCeiListForTable(this.ceiList);
      this.list.forEach(el => {
        this.map[el.ceiId] = el;
      });
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    countForField(cei, field) {
      let data = this.map[cei.id];
      if (!data) return '';
      let count = data[field];
      return count ? count : '';
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      let p2;
      if (year !== 0) {
        p1 = ceiService.findAllByYear(year);
        p1.then(data => this.ceiList = data);

        p2 = bilanCondTravailService.findSyntheseByYear(year);
        p2.then(data => this.list = data);
      } else {
        p1 = ceiService.findAllCurrent();
        p1.then(data => this.ceiList = data);

        p2 = bilanCondTravailService.findSyntheseByCurrentYear();
        p2.then(data => this.list = data);
      }

      Promise.all([p1, p2]).then(() => this.afterPromise());
    },
    download() {
      this.isExportDownloading = true;
      let p = bilanCondTravailService.download(this.year);
      let promises = [p];
      p.catch(e => {
        this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isExportDownloading = false;
      });
    },
    getElementWidth(id) {
      if (!document.getElementById(id)) return
      return document.getElementById(id).getBoundingClientRect().offsetLeft;
    },
  },
  computed: {
    map() {
      let map = {};
      this.list.forEach(el => map[el.ceiId] = el);
      return map;
    },
  },
  watch: {
    year(newType) {
      this.reload(newType);
    },
  },
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_cond_travail.list')" :displayGoBack=false>
    <template v-slot:action>
      <button v-if=canDownloadBilanVH() @click="download()" style="margin-right: 10px;" class="btn btn-inverse-primary"
              :disabled="isExportDownloading">
        <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
        <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
        {{ $t('download_excel') }}
      </button>
      <RouterLink v-if="canEditHealthcare()" :to="{ name: 'bilanCondTravailList' }" class="btn btn-inverse-primary">
        <i class="fe fe-settings pr-1"/>
        {{ $t('manage') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="row">
        <AkDropdown :label="$t('year')" v-if=canViewHealthcareArchived v-model="year" :options=this.yearList
                    class-name="col-md-2"/>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive forecast-table">
                <table style="display: block; overflow-x: auto;">
                  <thead>
                  <tr>
                    <th scope="col" rowspan="2">{{ $t('ager_label') }}</th>
                    <th scope="col" rowspan="2">{{ $t('uer_label') }}</th>
                    <th scope="col" rowspan="2">{{ $t('cei_label') }}</th>
                    <th scope="col">{{ $t('bilan_cond_travail.title1') }}</th>
                    <th scope="col" colspan="8">{{ $t('bilan_cond_travail.title2') }}</th>
                    <th scope="col" rowspan="2">{{ $t('bilan_cond_travail.nb9') }}</th>
                    <th scope="col" rowspan="2">{{ $t('bilan_cond_travail.nb10') }}</th>
                    <th scope="col" colspan="5">{{ $t('bilan_cond_travail.title3') }}</th>
                  </tr>
                  <tr>
                    <th scope="col" v-for="field in localFields" :key="field">{{ $t('bilan_cond_travail.' + field) }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="cei in ceiList" :key="cei.id">
                    <td v-if=cei.agerSize :rowspan=cei.agerSize>{{ cei.agerLabel }}</td>
                    <td v-if=cei.uerSize :rowspan=cei.uerSize>{{ cei.uerLabel }}</td>
                    <td>{{ cei.label }}</td>
                    <td v-for="field in dbFields" :key="field">{{ countForField(cei, field) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!--            <br>-->
              <!--            <div class="table-wrapper forecast-table">-->
              <!--              <table id="table">-->
              <!--                <thead>-->
              <!--                <tr>-->
              <!--                  <th class="sticky-col" scope="col" rowspan="2" style="left: 0; z-index: 80;">{{-->
              <!--                      $t('ager_label')-->
              <!--                    }}-->
              <!--                  </th>-->
              <!--                  <th class="sticky-col" scope="col" rowspan="2" style="left: 61px; z-index: 80;">{{-->
              <!--                      $t('uer_label')-->
              <!--                    }}-->
              <!--                  </th>-->
              <!--                  <th class="sticky-col" scope="col" rowspan="2" style="left: 144.5px; z-index: 80;">-->
              <!--                    {{ $t('cei_label') }}-->
              <!--                  </th>-->
              <!--                  <th scope="col">{{ $t('bilan_cond_travail.title1') }}</th>-->
              <!--                  <th scope="col" colspan="8">{{ $t('bilan_cond_travail.title2') }}</th>-->
              <!--                  <th scope="col" rowspan="2">{{ $t('bilan_cond_travail.nb9') }}</th>-->
              <!--                  <th scope="col" rowspan="2">{{ $t('bilan_cond_travail.nb10') }}</th>-->
              <!--                  <th scope="col" colspan="5">{{ $t('bilan_cond_travail.title3') }}</th>-->
              <!--                </tr>-->
              <!--                <tr>-->
              <!--                  <th scope="col" v-for="field in localFields" :key="field">{{ $t('bilan_cond_travail.' + field) }}-->
              <!--                  </th>-->
              <!--                </tr>-->
              <!--                </thead>-->
              <!--                <tbody>-->
              <!--                <tr v-for="cei in ceiList" :key="cei.id">-->
              <!--                  <td v-if=cei.agerSize :rowspan=cei.agerSize style="left: 0; z-index: 50;">{{ cei.agerLabel }}</td>-->
              <!--                  <td v-if=cei.uerSize :rowspan=cei.uerSize style="left: 61px; z-index: 50;">{{ cei.uerLabel }}</td>-->
              <!--                  <td style="left: 144.5px; z-index: 50;">{{ cei.label }}</td>-->
              <!--                  <td v-for="field in dbFields" :key="field">{{ countForField(cei, field) }}</td>-->
              <!--                </tr>-->
              <!--                </tbody>-->
              <!--              </table>-->
              <!--            </div>-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>
<style scoped>
#table > tbody > tr > td:nth-child(-n + 3) {
  top: 346px;
  background: #fff;
  position: sticky;
  width: min-content;
}

#table > table thead tr.first th {
  padding: 3px;
}

#table > table thead tr.second th {
  padding: 3px;
  position: -webkit-sticky;
}

#table > table thead tr.third th {
  padding: 3px;
  position: -webkit-sticky;
}
</style>